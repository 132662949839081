import React, { useEffect, useState } from 'react'
import { withContext } from '../../../context/AppContext'
import {
  LoadingProgressBar,
} from '@ix/ix-ui'
import { AppContextType } from '../../../context/AppContext.type'
function BackgroundTaskProgress (
  {
    lastMessage,
  }: AppContextType,
) {
  const [backgroundTaskCurrentStatus, setBackgroundTaskCurrentStatus] = useState({
    total_records: 0,
    processed: 0,
  })

  useEffect(() => {
    if (lastMessage !== null) {
      if (lastMessage.type === 'update_in_progress') {
        const messageDetails = lastMessage.message
        if (
          (typeof messageDetails !== 'object') ||
          (messageDetails === null) ||
          !('total_records' in messageDetails) ||
          (typeof messageDetails.total_records !== 'number') ||
          !('processed' in messageDetails) ||
          (typeof messageDetails.processed !== 'number')
        ) {
          throw Error('Invalid progresss message')
        }
        setBackgroundTaskCurrentStatus({
          total_records: messageDetails.total_records,
          processed: messageDetails.processed,
        })
      } else if (lastMessage?.type === 'completed') {
        setBackgroundTaskCurrentStatus({
          total_records: 0,
          processed: 0,
        })
      }
    }
  }, [lastMessage])

  if (backgroundTaskCurrentStatus.processed && lastMessage?.type !== 'completed') {
    return <div>
      <LoadingProgressBar
        currentProgress={backgroundTaskCurrentStatus.processed}
        totalCompleted={backgroundTaskCurrentStatus.total_records}
      />
    </div>
  }
  return null
}

export default withContext(BackgroundTaskProgress)
