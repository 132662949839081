import { AxiosPromise } from 'axios'
import Api, { getSPUDAPIUrl } from '../helpers/api'

export type BulkUpdateSendEmailDetails = {
  email: string,
  name: string,
  additional_content: string,
  record_toggle: boolean,
  site_id: number,
  template: string,
}

export const bulkUpdateSendEmail = async (
  emailDetails: BulkUpdateSendEmailDetails,
): Promise<AxiosPromise<{
  message: string
  bulk_update_url: string
}
  >> => {
  return await Api.post(`${getSPUDAPIUrl()}bulk-update/send-email/`, emailDetails)
}
