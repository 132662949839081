import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Button, PopoutMenu, PopoutList, Title, Text } from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEllipsisH,
  faSave,
  faTrash,
  faCopy,
  faEnvelope,
  faCheckCircle,
  faBars,
  faTrashRestore,
  faExclamationTriangle,
  faCircleNotch,
} from '@fortawesome/free-solid-svg-icons'
import { getScrollPosition } from '../../effects/scrollPosition'
import { format, isDate } from 'date-fns'
import { IsMobile } from '../../effects/IsMobile'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { SPUDToggle } from '../../helpers/record'
import { formatDatetimeToLocal } from '../../helpers/datetime'
import DatePicker from 'react-datepicker'
import { withContext } from '../../context/AppContext'
import { AppContextType, DialogOptions } from '../../context/AppContext.type'
import { useAuth } from '../../helpers/auth'

export const CustomDatePicker = styled(DatePicker)`
  width: 100%;
  padding: 5px;
  border-radius: 3px;
  border: 1px solid #3a8ae8;
`

const ControlBarContainer = styled.div<{ sticky: boolean, scrolled: boolean }>`
  background-color: #E3EDFA;
  width: 100%;
  max-width: 100%;
  padding: 1em;
  padding-right: ${({ scrolled }) => (scrolled ? '20em' : '1.5em')};
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  margin-bottom: 1em;
  display: flex;
  justify-content: space-between;
  overflow: visible; 
  position: ${props => props.sticky ? 'fixed' : 'inherit'};
  z-index: 5;
  margin-left: 0;
  top: 0;
  height: 75px;
  > button {
    font-size: 14px;
  }
  transition: background-color 0.05s ease, padding-right 0.3s ease;
`

const RightHandSideControls = styled.div<{ showRightPanel: boolean }>`
  display: flex;
  justify-content: ${props => (props.showRightPanel ? 'space-between' : 'flex-end')};
  align-items: center;
  gap: 0.75em;
  padding-right: 1.5em;
  flex-shrink: 0;
`
const LeftHandSideControls = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: flex-start;
`

const SpudSaveButton = styled(Button)`
  font-size: 12px;

  @media ${({ theme }) => theme.breakpoints.xl} {
    display: flex;
    gap: 2px;

    .save-text {
      display: flex;
      align-items: center;
      gap: 5px; 
    }
  }
`

const SavedDateText = styled.div`
  font-size: 13px;
  color: #808080;
`

const RightPanelButton = styled(Button)<{ showRightPanel: boolean }>`
  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    background-color: ${({ showRightPanel, theme }) =>
    (showRightPanel ? theme.colors.grayLighter : theme.colors.textPrimary)};
    color: ${({ showRightPanel, theme }) => (showRightPanel ? theme.colors.grayBase : theme.colors.colorBase)};
    
    &:hover {
      background-color: ${({ showRightPanel, theme }) =>
    (showRightPanel ? theme.colors.grayLighter : theme.colors.grayBase)};
    }
  }
`

const PopoutMenuWrapper = styled.div`
  position: relative;
  display: inline-block;
`
const StyledPopoutMenu = styled(PopoutMenu)`
  top: 100%;
  right: 0;
  z-index: 10;
  background-color: #e3edfa;
  border-radius: 3px;
  overflow: visible;
`

type Props = {
  onSaveRecord: (status: string) => void
  onShowRightPanel: (show: boolean) => void
  onSubmitForReview: () => void
  onPublish: () => void
  validateForm: (saveType: 'draft' | 'pending' | 'published', canShowDialog: boolean) => Promise<boolean | undefined>
  recordId?: number | null | string,
  lastUpdated: Date | undefined,
  loading: boolean,
  pageLoading: boolean,
  hasSaved: boolean,
  isNewRecord: boolean,
  setDialogOptions?: (options: DialogOptions) => Dispatch<SetStateAction<DialogOptions>>,
  onUpdateToggle: (validated: boolean) => void
  recordType: string | undefined,
  onLastUpdateChange: (validated: boolean, date: Date) => void
  onDeleteRecord: () => void,
  onRestoreRecord: () => void,
  onCopyRecord: () => void,
  sendEmail: () => void,
  canRecordBePublished: () => boolean,
  actionButtonsDisabled: {
    copy: boolean,
    delete: boolean,
    restore: boolean,
    eReferral: boolean,
    active_iss_entities: Array<{
      id: string,
      name: string
    }>
  },
  active: boolean,
  showDialog: boolean,
  actionButtonValidationLoading: boolean,
  setShowDialog: (flag: boolean) => void,
  dontSendBulkUpdateEmail: boolean,
  siteId: number | null,
} & AppContextType

const PublishButton = styled(Button)<{ showRightPanel: boolean }>`
  margin: 10px 0px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
    line-height: 1.5rem;
  }
`

function AddEditRecordControlBar (
  {
    onSaveRecord,
    onSubmitForReview,
    onPublish,
    recordId,
    loading,
    pageLoading,
    onShowRightPanel,
    hasSaved,
    setDialogOptions,
    isNewRecord,
    onUpdateToggle,
    lastUpdated,
    onLastUpdateChange,
    recordType,
    onCopyRecord,
    onDeleteRecord,
    onRestoreRecord,
    actionButtonsDisabled,
    canRecordBePublished,
    active,
    actionButtonValidationLoading,
    validateForm,
    showDialog,
    setShowDialog,
    setPopupType,
    setPopupOptions,
    dontSendBulkUpdateEmail,
    siteId,
  }: Props): React.ReactElement {
  const scrollPosition = getScrollPosition()
  const AddEditRecordControlBarRef = useRef<HTMLDivElement>(null)
  const [showRightPanel, setShowRightPanel] = useState(false)

  const isMobile = IsMobile()

  const [savedDate, setSavedDate] = useState<Date | undefined>()
  const [toggleUpdateDate, setToggleUpdateDate] = useState(false)

  const { userRole, updaterThatCanPublish } = useAuth()

  useEffect(() => {
    if (hasSaved) {
      setSavedDate(new Date())
      setToggleUpdateDate(false)
    }
  }, [hasSaved])

  useEffect(() => {
    if (isNewRecord || pageLoading) {
      setToggleUpdateDate(false)
      setSavedDate(undefined)
    }
  }, [isNewRecord, pageLoading])

  const disableButtons = pageLoading || (!!(recordId && !active))

  useEffect(() => {
    if (setDialogOptions && recordId && showDialog) {
      if ((userRole === 'Updater' && updaterThatCanPublish) || userRole !== 'Updater') {
        if (canRecordBePublished()) {
          setDialogOptions({
            onConfirm: onPublish,
            onDismiss: () => {
              setShowDialog(false)
            },
            title: !toggleUpdateDate ? 'Publish record' : 'Publish record and change update date',
            description: <ul style={{ listStyle: 'none', padding: '1em', textAlign: 'left' }}>
              <li>{!toggleUpdateDate
                ? <span style={{ fontWeight: 'bold', fontSize: '20px' }}>
                 Are you sure you want to publish?</span>
                : <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                  Are you sure you want to publish and change the update date?</span>
              }
              </li>
              {!toggleUpdateDate && <li style={{ color: 'red', fontWeight: 'bold', fontSize: '20px' }}>
                <strong>Note: the update date has not changed</strong></li>}
            </ul>,
            show: true,
            type: 'confirm',
          })
        } else {
          setDialogOptions({
            onConfirm: () => {
              setShowDialog(false)
            },
            onDismiss: () => {
              setShowDialog(false)
            },
            title: 'Record can\'t be published',
            description: <ul style={{ listStyle: 'none', padding: '1em', textAlign: 'left' }}>
              <li aria-label={`failed to publish ${recordType}`}>
                {recordType === 'site' && 'The organisation selected has not been published'}
                {recordType === 'service' && 'The linked site has not been published'}
              </li>
            </ul>,
            show: true,
            type: 'acknowledge',
          })
        }
      } else {
        setDialogOptions({
          onConfirm: onSubmitForReview,
          onDismiss: () => {
            setShowDialog(false)
          },
          title: !toggleUpdateDate ? 'Submit for review' : 'Submit for review and change update date',
          description: <ul style={{ listStyle: 'none', padding: '1em', textAlign: 'left' }}>
            <li>{!toggleUpdateDate
              ? <span style={{ fontWeight: 'bold', fontSize: '18px' }}>
               Are you sure you want to submit for review?</span>
              : <span style={{ fontWeight: 'bold', fontSize: '16px' }}>
                Are you sure you want to submit for review and change update date?</span>
            }</li>
            {!toggleUpdateDate && <li style={{ color: 'red', fontSize: '20px' }}>
              <strong>Note: the update date has not changed</strong></li>}
          </ul>,
          show: true,
          type: 'confirm',
        })
      }
    } else {
      setShowDialog(false)
    }
  }, [showDialog])

  return (
    <ControlBarContainer ref={AddEditRecordControlBarRef}
      sticky={scrollPosition > 100}
      scrolled={scrollPosition > 100}
    >
      <LeftHandSideControls>
        <SpudSaveButton
          active={!disableButtons}
          disabled={disableButtons}
          onClick={() => {
            setShowDialog(false)
            onSaveRecord(userRole === 'Updater' ? 'draft' : 'pending')
          }}
          loading={loading}
        >
          <span className="save-text">
            {!loading && <FontAwesomeIcon icon={faSave as IconProp} />} Save
          </span>
          <span className="status-text">
            {userRole === 'Updater' && !updaterThatCanPublish ? '(draft)' : '(pending)'}
          </span>
        </SpudSaveButton>
        <SavedDateText>
          {savedDate && `Saved ${format(savedDate, 'h:mm aaa')}`}
        </SavedDateText>
      </LeftHandSideControls>
      <RightHandSideControls showRightPanel={isMobile}>
        {toggleUpdateDate && (
          <div style={{ width: '23em' }}>
            <CustomDatePicker
              selected={(lastUpdated && new Date(lastUpdated))}
              dateFormat='dd/MM/yyyy hh:mm aaa'
              name='Updated date datepicker'
              maxDate={new Date()}
              showTimeSelect
              onChange={(date: Date) => {
                if (date && isDate(date)) {
                  onLastUpdateChange(false, date)
                }
              }}
            />
          </div>
        )}
        {!toggleUpdateDate && !pageLoading && !isNewRecord &&
          <Text aria-label='Update date'>{formatDatetimeToLocal(lastUpdated?.toString())}</Text>}
        <SPUDToggle
          label={<Title level={4} marginTop='0px'>Updated</Title>}
          disabled={!recordId || pageLoading || !active}
          checked={toggleUpdateDate}
          padding='1em'
          onChange={(checked: boolean) => {
            setToggleUpdateDate(checked as boolean)
            onUpdateToggle(checked as boolean)
          }}
          onKeyDown={(key: React.KeyboardEvent<HTMLInputElement>) => {
            const checked = toggleUpdateDate
            if (key.key === 'Enter') {
              setToggleUpdateDate(!checked)
              onUpdateToggle(!checked)
            }
          }}
          offColor="#F4F6F9"
        />
        <PublishButton
          showRightPanel={showRightPanel}
          active={!disableButtons && !isNewRecord}
          disabled={!recordId || disableButtons || isNewRecord}
          title='The form will be validated prior to submission'
          onClick={() => {
            validateForm(userRole === 'Updater' ? 'pending' : 'published', true)
          }}
          loading={loading}
          aria-label={(userRole === 'Updater' && updaterThatCanPublish) || userRole !== 'Updater'
            ? 'Publish'
            : 'Submit for review'}
        >
          {!loading && <FontAwesomeIcon icon={faCheckCircle as IconProp}/>}{' '}
          {(userRole === 'Updater' && updaterThatCanPublish) || userRole !== 'Updater'
            ? 'Publish'
            : 'Submit for review'}
        </PublishButton>
        <PopoutMenuWrapper>
          {!actionButtonValidationLoading &&
            <StyledPopoutMenu
              label={<FontAwesomeIcon icon={faEllipsisH as IconProp} color='#222' aria-label="More Actions" />}
              buttonBackgroundColour='#e3edfa'
              buttonBackgroundHoverColour='#e0e6ee'
              menuItems={[
                { label: 'Copy', icon: faCopy, method: onCopyRecord, disabled: !actionButtonsDisabled.copy },
                {
                  label: actionButtonsDisabled.restore ? 'Restore' : 'Delete',
                  icon: actionButtonsDisabled.restore ? faTrashRestore : faTrash,
                  method: actionButtonsDisabled.restore ? onRestoreRecord : onDeleteRecord,
                  disabled: actionButtonsDisabled.restore ? false : !actionButtonsDisabled.delete,
                },
                {
                  label: 'Email',
                  icon: faEnvelope,
                  method: () => {
                    setPopupOptions({
                      recordType: '',
                      total: 0,
                      dontSendBulkUpdateEmail,
                      siteId,
                    })
                    setPopupType('email-form')
                  },
                  disabled: false,
                },
              ]}
            />
          }
        </PopoutMenuWrapper>

        {actionButtonValidationLoading && <FontAwesomeIcon icon={faCircleNotch as IconProp} fixedWidth spin />}
        {actionButtonsDisabled.active_iss_entities.length > 0 && !actionButtonsDisabled.delete &&
          <PopoutList
            label={<FontAwesomeIcon icon={faExclamationTriangle as IconProp} color='red'/>}
            contentLabel={'Active records in iss4:'}
            listItems={actionButtonsDisabled.active_iss_entities.map(activeEntity => (
              <li key={activeEntity.id}>
                {activeEntity.name} ({activeEntity.id})
              </li>
            ))}
          />
        }
        {isMobile && (
          <RightPanelButton
            primary
            showRightPanel={showRightPanel}
            title="The form will be validated prior to submission"
            onClick={() => {
              onShowRightPanel(!showRightPanel)
              setShowRightPanel(!showRightPanel)
            }}
          >
            <FontAwesomeIcon icon={faBars as IconProp} />
          </RightPanelButton>
        )}
      </RightHandSideControls>
    </ControlBarContainer>
  )
}

export default withContext(AddEditRecordControlBar)
