import { useAuth } from '../helpers/auth'
import { Button, Col, Row, Title } from '@ix/ix-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { AddNewButton, getPageType } from '../helpers/record'
import React, { Dispatch, SetStateAction } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { PopUpOptions } from '../context/AppContext.type'

export const fetchCorrectHeading = (
  pageType: string,
  recordType: string,
  setPopupType?: Dispatch<SetStateAction<
    'allocate' | 'bulk-update'| 'bulk-delete' | 'export' | 'import' | 'review' | 'email-form'| null
  >>,
  setPopupDismissedWithAction?: Dispatch<SetStateAction<boolean>>,
  setPopupOptions?: Dispatch<SetStateAction<PopUpOptions>>,
  total?: number,
) => {
  const { userRole } = useAuth()
  switch (pageType) {
  case 'record-list':
    return <Row>
      <Col md={12} lg={4} xl={4} >
        <Row align="baseline">
          <Title level={1}>Records</Title>
          <Title level={1} padding="0 10px 20px 10px">
            <FontAwesomeIcon icon={faChevronRight as IconProp} fixedWidth />
          </Title>
          <Title level={1}>
            {getPageType(recordType)}s
          </Title>
        </Row>
      </Col>
      <Col md={12} lg={8} xl={8} >
        <Row justify="flex-end">
          {
            setPopupType &&
            setPopupDismissedWithAction &&
            userRole === 'Administrator' &&
            <Button
              primary
              onClick={() => {
                setPopupDismissedWithAction(false)
                setPopupOptions?.({
                  recordType: recordType,
                  total: total || 0,
                })
                setPopupType('import')
              }}
            >
              Import
            </Button>
          }
          <AddNewButton record={recordType} />
        </Row>
      </Col>
    </Row>
  case 'dashboard':
    return <Row>
      <Col md={12} lg={4} xl={4} >
        <Row align="baseline">
          <Title level={1}>
            {userRole} Dashboard
          </Title>
        </Row>
      </Col>
      <Col md={12} lg={8} xl={8} >
        <Row justify="flex-end">
          <AddNewButton record='site' />
        </Row>
      </Col>
    </Row>
  case 'search':
    return <Row>
      <Col md={12} lg={4} xl={4} >
        <Row align="baseline">
          <Title level={1}>
            Search results
          </Title>
        </Row>
      </Col>
    </Row>
  default:
    return null
  }
}
