import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import {
  Row,
  Col,
  Alert,
  Accordion,
  QuickAccessToolbar,
} from '@ix/ix-ui'
import {
  fetchCorrectComponent,
  fetchFormDataStructure,
  generateQuickAccessToolbar,
  getFormConfig,
  validateSections,
  getPageType,
  fetchCorrectIdToDisplay,
  constructDefaultFormData,
  hasFormBeenSubmitted,
  formatErrorMessage,
  SPUDFormFieldset,
} from '../../helpers/record'
import { RouteComponentProps, useHistory } from 'react-router'
import { AppContextType } from '../../context/AppContext.type'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { SPUDOnSaveResponseDetails, SPUDRecordWithData } from '../../../@types/SPUDRecord.type'
import AddEditRecordControlBar from './AddEditRecordControlBar'
import styled from 'styled-components'
import toast, { Toaster } from 'react-hot-toast'
import SPUDToastNotification from '../General/SPUDToastNotification'
import { getScrollPosition } from '../../effects/scrollPosition'
import { useAuth } from '../../helpers/auth'
import { CheckRecordActionResponseType } from '../../services/transactions.service'
import { copyRecordHandler, deleteRecordHandler, restoreRecordHandler } from './AddEditRecord.utils'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
import { LinkedRecord } from '../../../@types/LinkedRecord.type'
import { AgeGroupType, BulkServiceProviderUpdateType } from '../../services/spud.service'
import {
  convertCommaSepStringToArray,
  getFocusFieldName, getValidationRules,
  setFieldFocus,
  setLocationFieldFocus,
} from './AddEditService.service'
import SPUDFieldCollapse from '../General/SPUDFieldCollapse'
import { withContext } from '../../context/AppContext'

type matchParams = {
  recordType?: string,
  action?: string,
  revisionId?: string,
}

interface DynamicDivProps { /* since this isnt in the component library */
  scrollPosition: number;
  width?: number;
}

type Props = RouteComponentProps<matchParams> & AppContextType & {
  recordId?: number|null|string,
  loadingForm: boolean,
  isNewRecord: boolean,
  action: string | undefined,
  recordType: string | undefined,
  formData: SPUDRecordWithData<SPUDSiteRecordDetails | SPUDOrganisationRecordDetails | SPUDServiceRecordDetails>,
  bulkUpdateChanges: BulkServiceProviderUpdateType | undefined
  onSaveCallback: (
    response: SPUDOnSaveResponseDetails | null,
    refetch?: boolean | undefined) => void,
  appendValue: {
    site?: number | null | string,
    organisation?: number | null | string
  } | null,
  onShowRightPanel: (show: boolean) => void,
  resetBulkUpdateRecordChanges: boolean
  setResetBulkUpdateRecordChanges: Dispatch<SetStateAction<boolean>>
  linkedRecords: Array<LinkedRecord>
  ageGroups?: {
    data: {
      results: Array<AgeGroupType>
      next: string | null
    }
  }
}

const ControlBarContainer = styled.div<{sticky: boolean}>`
  transition: background-color 0.05s ease;
  position: ${props => props.sticky ? 'fixed' : 'inherit'};
  margin-top: 0;
  height: ${props => props.sticky ? 'auto' : 'inherit'};
  top: ${props => props.sticky ? '80px' : '0'};
`
const DynamicDiv = styled.div<DynamicDivProps>`
  height: ${window.innerWidth <= 1350 ? 'auto' : '75px'};
  margin-bottom: 1em;
  width: ${(props) => (props.scrollPosition > 100 ? props.width || '100%' : 'auto')};
`

function AddEditRecord (
  {
    recordType,
    appendValue,
    spudSave,
    spudUpdate,
    spudCopyService,
    spudDelete,
    spudRestore,
    onSaveCallback,
    formData,
    bulkUpdateChanges,
    recordId,
    loadingForm,
    isNewRecord,
    onShowRightPanel,
    checkRecordActions,
    setDialogOptions,
    linkedRecords,
    resetBulkUpdateRecordChanges,
    setResetBulkUpdateRecordChanges,
    ageGroups,
    // setPopupType,
  }: Props): React.ReactElement {
  const [changeStatus, setChangeStatus] = useState(false)
  const [quickAccessExpanded, setQuickAccessExpanded] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [hasSaved, setHasSaved] = useState(false)
  const [actionButtonValidationLoading, setActionButtonValidationLoading] = useState(false)
  const [quickAccessButtonClicked, setQuickAccessButtonClicked] = useState('')
  const [showDialog, setShowDialog] = useState(false)
  const [actionButtonsDisabled, setActionButtonsDisabled] = useState<CheckRecordActionResponseType>(
    {
      copy: false,
      delete: false,
      restore: false,
      eReferral: false,
      active_iss_entities: [],
    },
  )

  const formRef = useRef<HTMLDivElement>(null)

  const scrollPosition = getScrollPosition()
  const QuickAccessMenuContainerRef = useRef<HTMLDivElement>(null)

  const { userRole } = useAuth()
  const history = useHistory()

  const methods = useForm({
    defaultValues: constructDefaultFormData(recordType),
    mode: 'all',
    progressive: true,
  })
  const [formRecordType, setFormRecordType] = useState<string>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(loadingForm)
  }, [loadingForm])

  useEffect(() => {
    if (isNewRecord) {
      methods.reset(constructDefaultFormData(recordType))
    }
  }, [isNewRecord])
  // disabled because shape of the submitted data isn't known till runtime
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildFormData = <T extends {[x: string]: any}>(data: T, newStatus?: string) => {
    const formConfig = getFormConfig(recordType)
    let payloadStructure = fetchFormDataStructure(recordType)
    const payloadSchema = formConfig?.flatMap(
      group => group.fields.map(field => field.name))
    const multiSelectFields = formConfig?.flatMap(
      group => group.fields.map(field => field.type === 'multiselect' && field.name))

    let payloadObj: Record<string, unknown> = {}
    if (payloadSchema) {
      for (const field of payloadSchema) {
        if (multiSelectFields &&
          multiSelectFields.includes(field) &&
          data &&
          data[field] &&
          Array.isArray(data[field])
        ) {
          if (['datasets', 'age_groups'].includes(field)) {
            payloadObj[field] = data[field].map((
              value: { id: number | string, name: string } | string) =>
              typeof value === 'string' ? value : typeof value?.id === 'string' ? value.id : value.name,
            )
          } else {
            payloadObj[field] = data[field].map((
              value: { id: number | string, name: string } | string) =>
              typeof value === 'string' ? value : value?.id,
            )
          }
        } else if (field === 'crisis_keywords' && data[field]) {
          payloadObj[field] = data[field].map((
            value: { id: number | string, name: string } | string) =>
            typeof value === 'string' ? value : typeof value?.id === 'string' ? value.id : value.name,
          )
        } else {
          payloadObj[field] = data?.[field]
        }
      }
    }

    if (appendValue) {
      payloadObj = { ...appendValue, ...payloadObj }
    }

    // apply the new status to the payload
    if (newStatus && payloadStructure) {
      payloadStructure = { ...payloadStructure, status: newStatus }
    }

    if (!recordId && !payloadObj?.date_last_updated) {
      payloadObj.date_last_updated = new Date()
    }
    return { ...payloadStructure, data: { ...payloadObj } }
  }

  const onSubmit = <T extends {[x: string]: unknown}>(data: T, newStatus?: 'draft' | 'pending' | 'published') => {
    const generateSavedType = () => (
      newStatus && newStatus === 'pending'
        ? 'marked for review'
        : (newStatus || 'saved')
    )

    setLoading(true)
    setFormSubmitted(true)
    if (recordId && !isNewRecord) {
      spudUpdate(
        recordId,
        recordType,
        buildFormData(data, newStatus))
        .then((response) => {
          onSaveCallback(response.data, true)
          setLoading(false)
          setFormSubmitted(false)
          setChangeStatus(false)
          setHasSaved(true)
          setShowDialog(false)
          toast.custom(
            <SPUDToastNotification
              title="Success"
              message={
                <span>
                  {getPageType(recordType)} <strong>{response.data.data.name}
                    {' '}({response.data.revision.record})</strong> has been {generateSavedType()}
                </span>
              }
              success
            />,
            {
              position: 'bottom-center',
            },
          )
        }).catch((error) => {
          setLoading(false)
          setFormSubmitted(false)
          setChangeStatus(false)
          setShowDialog(false)
          toast.custom(
            <SPUDToastNotification
              title="Error"
              message={
                <span>
                  Something went wrong when saving <strong>{getPageType(recordType)} ({recordId})</strong>
                  {' '}please try again
                  <br/>
                  <strong>Error: </strong> {error?.response?.data?.detail}
                </span>
              }
              error
            />,
            {
              position: 'bottom-center',
            },
          )
        })
    } else {
      spudSave(
        recordType,
        buildFormData(data))
        .then((response) => {
          onSaveCallback(response.data)
          setLoading(false)
          setFormSubmitted(false)
          setHasSaved(true)
          setShowDialog(false)
          toast.custom(
            <SPUDToastNotification
              title="Created"
              message={
                <span>
                  {getPageType(recordType)} <strong>{response.data.data.name}
                    {' '}({
                      fetchCorrectIdToDisplay(
                        response.data.revision.record,
                        response.data.revision.iss_id,
                      )})</strong> has been created
                </span>
              }
              success
            />,
            {
              position: 'bottom-center',
            },
          )
        }).catch((error) => {
          setLoading(false)
          setFormSubmitted(false)
          setShowDialog(false)
          toast.custom(
            <SPUDToastNotification
              title="Error"
              message={
                <span>
                  Something went wrong on creating the <strong>{getPageType(recordType)}</strong>
                  {' '}please try again
                  <br/>
                  <strong>Error: </strong> {error?.message}
                </span>
              }
              error
            />,
            {
              position: 'bottom-center',
            },
          )
        })
    }
    setHasSaved(false)
  }

  const validateForm = async (saveType: 'draft' | 'pending' | 'published', canShowDialog: boolean) => {
    const formgroup = getFormConfig(recordType)
    const rules: string[] = []
    formgroup?.forEach(group => {
      group.fields.forEach(field => {
        if (getValidationRules(saveType, field, recordType)) {
          rules.push(field.name)
        }
      })
    })
    const result = await methods.trigger(rules)
    if (result && (saveType === 'published' || saveType === 'pending') && canShowDialog) {
      setShowDialog(true)
    } else if (!result && (saveType === 'published' || saveType === 'pending') && canShowDialog) {
      setShowDialog(false)
    } else {
      return result
    }
  }

  const onSubmitForReview = async () => {
    onSubmit(methods.getValues(), 'pending')
  }

  const onPublish = () => {
    onSubmit(methods.getValues(), 'published')
  }

  const simpleSave = async (status: string | undefined) => {
    if (await validateForm(status as 'draft' | 'pending', false)) {
      onSubmit(methods.getValues(), status as 'draft' | 'pending')
    }
  }

  useEffect(() => {
    console.log('----', formData?.data)
  }, [formData?.data])

  useEffect(() => {
    if (recordType !== formRecordType) {
      methods.reset(constructDefaultFormData(recordType))
      setShowDialog(false)
      setFormRecordType(recordType)
    } else {
      setShowDialog(false)
      if (typeof formData !== 'undefined' && formData !== null) {
        const data = formData.data
        if (data) {
          for (const formKey of Object.keys(data) as Array<
            keyof SPUDSiteRecordDetails & keyof SPUDOrganisationRecordDetails & keyof SPUDServiceRecordDetails>) {
            if (['crisis_keywords'].includes(formKey)) {
              if (!Array.isArray(data[formKey])) {
                methods.setValue(formKey,
                  convertCommaSepStringToArray(data[formKey] as string),
                )
              } else {
                methods.setValue(formKey, data[formKey])
              }
            } else {
              methods.setValue(formKey, data[formKey])
            }
          }
        }
      }
    }
  }, [recordType, formData?.data])

  // Some custom components include their own validation and errors,
  // and don't require the top level errors from being displayed
  const excludeTopLevelErrorsFromShowing = ['location', 'phones']

  const onUpdateDateChange = (checked: boolean, newDate?: Date) => {
    let lastUpdated
    if (checked) {
      lastUpdated = new Date()
    } else if (formData.data.date_last_updated) {
      lastUpdated = formData.data.date_last_updated
    }
    if (newDate) {
      lastUpdated = newDate
    }
    methods.setValue('date_last_updated', lastUpdated)
    // Field needs to be triggered for the value to be set properly
    methods.trigger('date_last_updated')
  }

  const actionCheck = async () => {
    const response = await checkRecordActions({ recordId: recordId, recordType })
    setActionButtonsDisabled(response.data)
    setActionButtonValidationLoading(false)
  }

  useEffect(() => {
    if (recordId) {
      setActionButtonValidationLoading(true)
      actionCheck()
    }
  }, [recordId])

  /**
   * Check if a record can be published by
   * making sure the parent records have already been published
   * Exception is an Organisation
   */
  const canRecordBePublished = (): boolean => {
    switch (recordType) {
    case 'service':
      return !!formData?.site?.iss_id
    default:
      return true
    }
  }

  // Jump to a related field and set focus when user clicks icon in Quick Access Bar.
  useEffect(() => {
    setTimeout(() => {
      switch (quickAccessButtonClicked) {
      case 'Location':
        return window.requestAnimationFrame(setLocationFieldFocus)
      case 'Contact':
      case 'Operational':
      case 'Legacy':
        return window.requestAnimationFrame(() => {
          setFieldFocus(getFocusFieldName(quickAccessButtonClicked))
        })
      default:
        return methods.setFocus(getFocusFieldName(quickAccessButtonClicked))
      }
    }, 500)
  }, [quickAccessButtonClicked])

  return (
    <div ref={formRef}>
      <DynamicDiv scrollPosition={scrollPosition} width={formRef?.current?.clientWidth}>
        <AddEditRecordControlBar
          recordId={recordId}
          onSaveRecord={(status) => simpleSave(status)}
          loading={loading && !loadingForm}
          pageLoading={loadingForm}
          onSubmitForReview={onSubmitForReview}
          validateForm={validateForm}
          onPublish={onPublish}
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          onShowRightPanel={(show) => {
            // A really hacky way for the dom to refresh the clientWidth so then when scrolled down
            // and the top bar is sticky it will fill the space when the right panel is closed, or
            // it will shrink when the right panel is open
            // It checks if it's sticky then scrolls down one place to trigger an update
            if (scrollPosition > 100) {
              window.scrollTo(0, scrollPosition + 1)
            }
            onShowRightPanel(show)
          } }
          hasSaved={hasSaved}
          onUpdateToggle={onUpdateDateChange}
          lastUpdated={methods.getValues('date_last_updated')}
          isNewRecord={isNewRecord}
          onLastUpdateChange={onUpdateDateChange}
          actionButtonsDisabled={actionButtonsDisabled}
          onCopyRecord={copyRecordHandler(
            recordId,
            recordType as string,
            formData?.organisation?.id || undefined,
            spudCopyService,
            setDialogOptions,
            history,
          )}
          canRecordBePublished={canRecordBePublished}
          recordType={recordType}
          onDeleteRecord={deleteRecordHandler(
            recordId as number,
            recordType as string,
            spudDelete,
            setDialogOptions,
            actionCheck,
            onSaveCallback,
            actionButtonsDisabled.eReferral,
          )}
          onRestoreRecord={restoreRecordHandler(
            recordId as number,
            recordType as string,
            spudRestore,
            setDialogOptions,
            actionCheck,
            onSaveCallback,
          )}
          active={(recordId && formData.is_active) || !recordId}
          actionButtonValidationLoading={actionButtonValidationLoading}
          sendEmail={() => {}}
          dontSendBulkUpdateEmail={
            'do_not_send_bulk_update_email' in formData?.data
              ? Boolean(formData.data.do_not_send_bulk_update_email)
              : false
          }
          siteId={formData.id}
        />
      </DynamicDiv>
      <Row>
        <Col direction='row' style={{ paddingRight: 0 }}>
          <ControlBarContainer ref={QuickAccessMenuContainerRef} sticky={scrollPosition > 100}>
            <QuickAccessToolbar
              buttonList={generateQuickAccessToolbar(getFormConfig(recordType),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                methods.formState.errors,
                hasFormBeenSubmitted(recordId, methods),
              )}
              expandedCallback={(expanded: boolean) => setQuickAccessExpanded(expanded)}
              clickedOptionCallback={(optionClicked: string) => setQuickAccessButtonClicked(optionClicked)}
            />
          </ControlBarContainer>
          <Toaster />
          <FormProvider {...methods} >
            <form
              style={{
                width: '100%',
                marginLeft: scrollPosition > 100 ? quickAccessExpanded ? '13em' : '5em' : '0',
              }}
              onSubmit={(evt) => evt.preventDefault()}
            >
              <SPUDFormFieldset disabled={loading || (!!(recordId && !formData.is_active))}>
                {
                  getFormConfig(recordType)?.length &&
                  getFormConfig(recordType)?.map(formGroup => (
                    <Accordion
                      padding='0 0 10px 10px'
                      heading={formGroup.title}
                      key={formGroup.title}
                      innerRef={formGroup.ref as React.RefObject<HTMLDivElement>}
                      icon={formGroup.icon}
                      status={validateSections(
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        methods.formState.errors,
                        formGroup, hasFormBeenSubmitted(recordId, methods),
                      )}
                      defaultOpen={true}
                      open={!!(quickAccessButtonClicked && quickAccessButtonClicked === formGroup.title)}
                      scrollIntoViewOnOpen
                    >
                      <>
                        {formGroup.fields.map(formElement => (
                          formElement.collapsable && !formElement.serviceProviderFormOnly
                            ? (
                              <SPUDFieldCollapse
                                key={formElement.name}
                                title={formElement.label}
                                defaultCollapsed={true}
                              >
                                <>
                                  <Controller
                                    name={formElement.name}
                                    control={methods.control}
                                    rules={ formElement.rules }
                                    render={({ field }) =>
                                      fetchCorrectComponent(formElement,
                                        !recordId || !changeStatus,
                                        userRole, formData, recordType,
                                        false,
                                        field,
                                        methods.getValues,
                                        methods.setValue,
                                        undefined,
                                        linkedRecords,
                                        formSubmitted,
                                        bulkUpdateChanges,
                                        loadingForm,
                                        resetBulkUpdateRecordChanges,
                                        setResetBulkUpdateRecordChanges,
                                      )
                                    }
                                  />
                                  {formElement?.name &&
                                  methods.formState.errors?.[formElement?.name] &&
                                  !excludeTopLevelErrorsFromShowing.includes(formElement?.name) &&
                                  <span>
                                    <Alert type="error">
                                      {formatErrorMessage(methods, formElement.label, formElement.name)}
                                    </Alert>
                                  </span>
                                  }
                                </>
                              </SPUDFieldCollapse>
                            )
                            : !formElement.serviceProviderFormOnly && <>
                              <Controller
                                key={formElement.name}
                                name={formElement.name}
                                control={methods.control}
                                rules={ formElement.rules }
                                render={({ field }) =>
                                  fetchCorrectComponent(formElement,
                                    !recordId || !changeStatus,
                                    userRole, formData, recordType,
                                    false,
                                    field,
                                    methods.getValues,
                                    methods.setValue,
                                    undefined,
                                    linkedRecords,
                                    formSubmitted,
                                    bulkUpdateChanges,
                                    loadingForm,
                                    resetBulkUpdateRecordChanges,
                                    setResetBulkUpdateRecordChanges,
                                    ageGroups,
                                  )
                                }
                              />
                              {formElement?.name &&
                              methods.formState.errors?.[formElement?.name] &&
                              !excludeTopLevelErrorsFromShowing.includes(formElement?.name) &&
                              <span>
                                <Alert type="error">
                                  {formatErrorMessage(methods, formElement.label, formElement.name)}
                                </Alert>
                              </span>
                              }
                            </>
                        ))}
                      </>
                    </Accordion>
                  ))
                }
              </SPUDFormFieldset>
            </form>
          </FormProvider>
        </Col>
      </Row>
    </div>
  )
}

export default withContext(AddEditRecord)
