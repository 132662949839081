import React from 'react'
import { AppContextType } from './AppContext.type'

const AppContext = React.createContext<AppContextType | null>(null)

export const withContext = function wrapComponent<
  T,
  TWithOptionalAppContext = Omit<T, keyof AppContextType>
    // & Partial<Extract<T, keyof AppContextType>>
> (
  Component: React.ComponentType<T>,
): React.ComponentType<TWithOptionalAppContext> {
  return function renderContext (props: TWithOptionalAppContext): React.ReactElement {
    return (
      <AppContext.Consumer>
        {/* Ignoring Because we are still using v17 of react and */}
        {/* @types/styled-components uses a wildcard to get types it pulls in react v18 */}
        {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/59765 */}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        {(globalState): React.ReactNode => <Component {...globalState} {...props} />}
      </AppContext.Consumer>
    )
  }
}

export default AppContext
