import React, { useState } from 'react'
import styled from 'styled-components'
import { Button, Row, Title } from '@ix/ix-ui'
import { SPUDToggle } from '../../helpers/record'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { withContext } from '../../context/AppContext'
import { AppContextType } from '../../context/AppContext.type'
import { bulkUpdateSendEmail } from '../../services/bulkUpdateSendEmail.service'
import SPUDToastNotification from '../../components/General/SPUDToastNotification'
import toast from 'react-hot-toast'

const SendBulkUpdateEmailHeader = styled.div`
  background-color: ${props => props.theme.dialogHeaderBackgroundColour};
  padding: 1em;
  border-bottom: 3px solid ${props => props.theme.dialogHeaderHighlightColour};
  font-size: 1.4em;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h4 {
    margin: 0;
  }
`
const SendBulkUpdateEmailFooter = styled.div`
  background-color: #ccc;
  padding: 1em;
  border-bottom: 3px solid ${props => props.theme.dialogHeaderHighlightColour};
  font-size: 1.4em;
  color: #D3D3D3;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
   flex-direction: row;
   
`
const SendBulkUpdateEmailCard = styled.div`
  z-index: 5;
  position: fixed;
  background-color: #fff;
  width: 60%;
  max-height: 80%;
  top: 10%;
  border-radius: 3px;
  box-shadow: 0 3px 6px 3px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
`
const SendBulkUpdateEmailErrorMessage = styled.p`
  color: red;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
`

const SendBulkUpdateEmailBody = styled.div`
  padding: 1em;
  display: flex;
  flex: 3;
  max-height: 70vh;
  overflow-y: scroll;
  flex-direction: column;
  align-items: stretch;
`
const SendBulkUpdateEmailCloseButton = styled.button`
  display: flex;
  justify-content: center;
  background: none;
  color: #fff;
  cursor: pointer;
  border: none;
  z-index: 1;
  font-size: 1.3em; 

  &:hover {
    background-color: rgb(255 255 255 / 22%);
    border-radius: 50%
  `

interface EmailFormProps {
  email: string;
  name: string;
  additional_content?: string;
  recordDetails: boolean;
}

 type SendBulkUpdateEmailProps = AppContextType & {
  dismissPopup: () => void;
}

export const SendBulkUpdateEmail = ({ dismissPopup, popupOptions }: SendBulkUpdateEmailProps) => {
  const [EmailData, setEmailData] = useState<EmailFormProps>({
    email: '',
    name: '',
    additional_content: '',
    recordDetails: false,
  })

  const [loading, setLoading] = useState(false)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target
    setEmailData({
      ...EmailData,
      [id]: value,
    })
  }

  const sendEmail = async (siteId: number) => {
    try {
      const response = await bulkUpdateSendEmail({
        email: EmailData.email,
        name: EmailData.name,
        additional_content: EmailData.additional_content || ' ',
        record_toggle: EmailData.recordDetails,
        site_id: siteId,
        template: 'bulk-update-on-demand',
      })
      setEmailData(EmailData)
      if (response.status === 200) {
        toast.custom(
          <SPUDToastNotification
            title="Success"
            message={<span>Email has been sent successfully</span>}
            success
          />,
          {
            position: 'bottom-center',
          },
        )
      } else {
        toast.custom(
          <SPUDToastNotification
            title="Error"
            message={<span>Failed to send email</span>}
            error
          />,
          {
            position: 'bottom-center',
          },
        )
      }
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = (e: React.FormEvent, siteId: number) => {
    e.preventDefault()
    sendEmail(siteId)
  }

  const renderNoSiteIdError = () => (
    <SendBulkUpdateEmailErrorMessage>
      This new site has not yet been saved and so can not be emailed.
    </SendBulkUpdateEmailErrorMessage>
  )

  const renderForm = (siteId: number) => (
    <form onSubmit={(e: React.FormEvent) => handleSubmit(e, siteId)}>
      <SendBulkUpdateEmailBody>
        {popupOptions.dontSendBulkUpdateEmail && (
          <SendBulkUpdateEmailErrorMessage>
            This site has been flagged as not receiving a bulk update email. Do you want to proceed?
          </SendBulkUpdateEmailErrorMessage>
        )}
        <label htmlFor="email"
          style={{
            fontWeight: 'bold',
            top: '0',
            fontSize: ' 16px',
            display: 'block',
            margin: '5px',
          }}
        >
          Email
        </label>
        <input
          type="email"
          id="email"
          style={{
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            fontSize: '16px',
            marginBottom: '15px',
          }}
          value={EmailData.email}
          onChange={handleChange}
          placeholder="Enter your recipient email"
          required
        />

        <label htmlFor="name"
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            top: '0',
            display: 'block',
            marginBottom: '10px',
          }
          }
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          style={{
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            fontSize: '16px',
            marginBottom: '15px',
          }}
          value={EmailData.name}
          onChange={handleChange}
          placeholder='Enter your recipient name'
          required
        />

        <label htmlFor="additional_content"
          style={{
            fontWeight: 'bold',
            fontSize: '16px',
            top: '0',
            display: 'block',
            marginBottom: '10px',
          }}
        >
        Extra Notes
        </label>
        <textarea
          id="additional_content"
          style={{
            padding: '20px 20px',
            border: '2px solid #ccc',
            borderRadius: '10px',
            fontSize: '16px',
            marginBottom: '15px',
          }}
          value={EmailData.additional_content}
          onChange={handleChange}
        >
        </textarea>
        <Row>
          <SPUDToggle
            label={<Title level={4} marginTop='0px'>Record Details</Title>}
            checked={EmailData.recordDetails}
            onChange={(checked: boolean) => {
              setEmailData({
                ...EmailData,
                recordDetails: checked,
              })
            }}
            offColor="#F4F6F9"
            padding="12px 0"
          />
        </Row>
      </SendBulkUpdateEmailBody>
      <SendBulkUpdateEmailFooter>
        <Button
          type="submit"
          disabled={loading}
          style={{
            backgroundColor: '#3A8AE8',
            right: '-94%',
            color: '#fff',
            margin: '0',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.3s',
          }}
        >
          {loading ? 'Submitting...' : 'Submit'}
        </Button>
      </SendBulkUpdateEmailFooter>
    </form>
  )

  return (
    <SendBulkUpdateEmailCard>
      <SendBulkUpdateEmailHeader>
        <h4>Update Email Details</h4>
        <SendBulkUpdateEmailCloseButton
          title='Close popup'
          onClick={() => dismissPopup()}
        >
          <FontAwesomeIcon icon={faTimes as IconProp}/>
        </SendBulkUpdateEmailCloseButton>
      </SendBulkUpdateEmailHeader>
      {!popupOptions.siteId ? renderNoSiteIdError() : renderForm(popupOptions.siteId)}
    </SendBulkUpdateEmailCard>
  )
}

export default withContext(SendBulkUpdateEmail)
