import React, { useEffect, useRef, useState } from 'react'
import AppContext from '../context/AppContext'
import { userService } from '../services/user.service'
import { transactionService } from '../services/transactions.service'
import { DuplicateType, FailedUpdateType, spudAPI, SuccessfulImportType } from '../services/spud.service'
import { getPlaceDetails, searchForAddress } from '../services/googleAPI.service'
import { useJsApiLoader } from '@react-google-maps/api'
import type { DialogOptions, PopupType } from '../context/AppContext.type'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { getWebsocketUrl } from '../helpers/api'
import { getFormConfig } from '../helpers/record'
import AllocateUsers from '../pages/RecordsPage/ListView/AllocateUsers'
import BulkUpdate from '../pages/RecordsPage/ListView/BulkUpdate'
import BulkDeleteRecord from '../pages/RecordsPage/ListView/BulkDeleteRecord'
import Export from '../pages/RecordsPage/ListView/Export'
import ImportRecords from '../pages/RecordsPage/ListView/ImportRecords/ImportRecords'
import BulkUpdateReview from '../pages/RecordsPage/ListView/BulkUpdate/BulkUpdateReview'
import { flattenSelectedRows, filterToUrlParams } from '../pages/RecordsPage/ListView/ListView.service'
import { PopUpOptions, SPUDWebSocketMessage } from '../context/AppContext.type'
import { AdvancedFilterType } from '../pages/RecordFilter/RecordFilter.service'
import { useAuth } from '../helpers/auth'
import SendBulkUpdateEmail from '../pages/RecordsPage/SendBulkUpdateEmail'

type Props = {
    children: React.ReactNode
}

function AppContextProvider (props: Props): React.ReactElement {
  const [popupType, setPopupType] = useState<
  PopupType
  >(null)
  const [websocketMessages, setWebsocketMessages] = useState<
    Array<SPUDWebSocketMessage<FailedUpdateType & SuccessfulImportType & DuplicateType>>
  >([])
  const [webSocketResults, setWebsocketResults] = useState<SPUDWebSocketMessage<{
    updated: number,
    failed: number,
    action: string,
    record_type: string
  }>>({
    message: {
      action: '',
      failed: 0,
      record_type: '',
      updated: 0,
    },
    type: '',
  })
  const [selectedRows, setSelectedRows] = useState({})
  const [selectAllRows, setSelectAllRows] = useState(false)
  const [pageFilters, setPageFilters] = useState<{[x: string]: AdvancedFilterType}>({})
  const [popupEntry, setPopupEntry] = useState<'filtered' | 'selected' | null>(null)

  let processedFilterParams: URLSearchParams = new URLSearchParams()
  if (pageFilters) {
    processedFilterParams = filterToUrlParams(pageFilters, processedFilterParams)
  }
  const filterQuery = processedFilterParams ? String(processedFilterParams) : ''

  const didUnmount = useRef(false)
  const googleAPI = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: window.GOOGLE_API_KEY,
    libraries: ['places'],
  })

  const [dialogOptions, setDialogOptions] = useState<DialogOptions>({
    show: false,
    title: '',
    description: '',
    type: 'confirm',
  })
  const [popUpOptions, setPopupOptions] = useState<PopUpOptions>({
    recordType: '',
    total: 0,
  })
  const [popupDismissedWithAction, setPopupDismissedWithAction] = useState(false)
  const { accessToken } = useAuth()
  const { sendMessage, lastJsonMessage, readyState, getWebSocket } = useWebSocket(
    getWebsocketUrl(accessToken),
    {
      retryOnError: true,
      reconnectAttempts: 2,
      reconnectInterval: 3000,
      shouldReconnect: (event) => event && !didUnmount.current,
    },
  )

  useEffect(() => {
    if (lastJsonMessage !== null && lastJsonMessage) {
      const lastMessage = lastJsonMessage as unknown as SPUDWebSocketMessage
      if (
        lastMessage.type !== 'completed' &&
        websocketMessages.some(message => message.type === 'completed')
      ) {
        if (lastMessage.type !== 'connected' && websocketMessages.length) {
          setWebsocketMessages([
            lastJsonMessage as unknown as SPUDWebSocketMessage<
              FailedUpdateType & SuccessfulImportType & DuplicateType
            >,
          ])
        }
      } else {
        if (lastMessage.type === 'completed') {
          setWebsocketResults(lastJsonMessage as unknown as SPUDWebSocketMessage<{
            updated: number,
            failed: number,
            action: string,
            record_type: string
          }>)
        }
        setWebsocketMessages([...websocketMessages,
          lastJsonMessage as unknown as SPUDWebSocketMessage<
            FailedUpdateType & SuccessfulImportType & DuplicateType
          >])
      }
    }
  }, [lastJsonMessage])

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState]

  const displayPopUp = () => {
    window.scrollTo(0, 0)
    switch (popupType) {
    case 'allocate':
      window.scrollTo(0, 0)
      return (
        <AllocateUsers
          recordType={popUpOptions.recordType}
          selectedRows={flattenSelectedRows(selectedRows)}
          total={popUpOptions.total}
          selectedAllRows={selectAllRows}
          dismissPopup={(allocated) => {
            setPopupType(null)
            if (allocated) {
              setPopupDismissedWithAction(true)
              setSelectAllRows(false)
              setSelectedRows({})
            }
          }}
        />)
    case 'bulk-update':
      return (
        <BulkUpdate
          recordType={popUpOptions.recordType}
          selectedRowsByNumber={flattenSelectedRows(selectedRows)}
          total={popUpOptions.total}
          selectedAllRows={selectAllRows}
          backgroundTaskResults={webSocketResults}
          dismissPopup={() => {
            setPopupType(null)
          }}
        />)
    case 'bulk-delete':
      return (
        <BulkDeleteRecord
          selectedRows={flattenSelectedRows(selectedRows)}
          recordType={popUpOptions.recordType}
          dismissPopup={() => setPopupType(null)}
          sendMessage={sendMessage}
        />
      )
    case 'export':
      window.scrollTo(0, 0)

      return (
        <Export
          recordType={popUpOptions.recordType}
          dismissPopup={() => setPopupType(null)}
          popupEntry={popupEntry}
          pageFilters={pageFilters}
          filterQuery={filterQuery}
          total={popUpOptions.total}
          selectedRows={flattenSelectedRows(selectedRows)}
          headers={getFormConfig(popUpOptions.recordType)}
        />)
    case 'import':
      return (
        <ImportRecords
          recordType={popUpOptions.recordType}
          dismissPopup={() => setPopupType(null)}
          sendMessage={sendMessage}
        />)
    case 'email-form':
      return (
        <SendBulkUpdateEmail
          dismissPopup={() => setPopupType(null)}
        />
      )
    case 'review':
      return (
        <BulkUpdateReview
          websocketMessages={websocketMessages}
          backgroundTaskResults={webSocketResults}
          dismissPopup={() => {
            setPopupType(null)
          }}
          sendMessage={sendMessage}
        />)
    default:
      return null
    }
  }

  const clearWsMessages = () => setWebsocketMessages([])

  return (
    <AppContext.Provider
      value={{
        recordList: transactionService.recordList,
        filterList: transactionService.filterList,
        countRecords: transactionService.countRecords,
        searchRecords: transactionService.searchRecords,
        recordDetail: transactionService.recordDetail,
        revisionUpdates: transactionService.revisionUpdates,
        checkRecordActions: transactionService.checkRecordActions,
        userList: userService.list,
        spudFetch: spudAPI.fetch,
        spudSave: spudAPI.create,
        spudUpdate: spudAPI.update,
        spudCopyService: spudAPI.copy,
        findAddress: searchForAddress,
        getPlaceDetails: getPlaceDetails,
        googleAPI,
        spudDelete: spudAPI.delete,
        spudRestore: spudAPI.restore,
        dialogOptions,
        setDialogOptions,
        websocket: getWebSocket,
        sendMessage: sendMessage,
        // Ignoring because lastJsonMessage doesn't accept a generic
        // so we can't Specify our lasMessage type of SPUDWebSocketMessage
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        lastMessage: lastJsonMessage,
        websocketConnected: connectionStatus,
        webSocketResults: webSocketResults,
        displayPopUp: displayPopUp,
        setPopupType: setPopupType,
        popupType,
        clearWsMessages: clearWsMessages,
        setSelectAllRows,
        setSelectedRows,
        selectedRows,
        selectAllRows,
        popupDismissedWithAction,
        setPopupDismissedWithAction,
        setPopupOptions: setPopupOptions,
        popupOptions: popUpOptions,
        pageFilters,
        setPageFilters,
        popupEntry,
        setPopupEntry,
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppContextProvider
