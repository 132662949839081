import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Title } from '@ix/ix-ui'
import SPUDUserAutocomplete from '../../../../components/General/SPUDUserAutocomplete'
import { flattenSelectedRows } from '../ListView.service'
import { Option } from '../../../../components/forms/AddEditRecord.type'
import { allocateUsers, AllocateRecordType } from '../../../../services/allocation.service'
import { userService } from '../../../../services/user.service'
import AllocateRecordsTable from './AllocateUserTable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

type AllocateUsersProps = {
  selectedRows: {[x: number]: {record_id: string | number, name: string}},
  recordType: string
  dismissPopup: (allocated: boolean) => void,
  selectedAllRows: boolean,
  total: number
}

const AllocatedUsersCard = styled.div`
  z-index: 5;
  position: fixed;
  background-color: #fff;
  min-width: 50%;
  min-height: 40%;
  top: 10%;
  border-radius: 3px;
  box-shadow: 0 3px 6px 3px rgb(0 0 0 / 15%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}) {
    top: 5%;
  }
`

const AllocatedUserHeader = styled.div`
  background-color: ${props => props.theme.dialogHeaderBackgroundColour};
  padding: 1em;
  border-bottom: 3px solid ${props => props.theme.dialogHeaderHighlightColour};
  font-size: 1.4em;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`
const AllocatedUserTitle = styled.h4`
  font-size: 1.4em;
  font-weight: bold;
  margin: 0;
`

const AllocatedUsersCloseButtonContainer = styled.div`
  padding: 5px;
`
const AllocatedUsersCloseButton = styled.button`
  border: 0;
  background: none;
  color: #fff;
  padding: 0;
  font-size: 1em;
  cursor: pointer;
  &:hover {
    background-color: rgb(255 255 255 / 22%);
    border-radius: 50%;
  }
`

const AllocatedUsersContentContainer = styled.div`
  padding: 1em;
  display: flex;
  flex: 3;
`

const AllocatedUsersContent = styled.div`
  height: 100%;
  width: 100%;
`

const AllocatedUsersControlBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  background-color: ${props => props.theme.colors.accentColor};
  padding: 1em;
`

const AllocatedUsersControlButtons = styled.button<{confirm: boolean}>`
  font-weight: bold;
  border: none;
  background-color: ${props => props.confirm ? props.theme.dialogConfirmColour : props.theme.colors.active};
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:not(:last-of-type) {
    margin-right: 5px;
  }
`

const AllocatedUsersConfirmButtonGroup = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`

const AllocateToAllContainer = styled.div`
  padding: 1em;
  width: 100%;
  z-index: 4;
`

function AllocateUsers (
  {
    selectedRows,
    recordType,
    dismissPopup,
    selectedAllRows,
  }: AllocateUsersProps) {
  const [allocateToAllUser, setAllocateToAllUser] = useState<Option | null>(null)
  const [recordAllocationArr, setRecordAllocationArr] = useState<Array<AllocateRecordType>>([])
  const [users, setUsers] = useState<Array<Option>>([])
  const [numberOfRows, setNumberOfRows] = useState(0)
  const [selectedRecords, setSelectedRecords] = useState<Array<{record_id: number | string | null, name: string}>>([])

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await userService.list({
        limit: 0, offset: 0, recordType: 'site',
      })
      const options = response.data.results.map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
      }))
      setUsers(options)
    }
    fetchUsers()
    setNumberOfRows(Object.keys(selectedRows).length)
  }, [])

  useEffect(() => {
    setSelectedRecords(flattenSelectedRows(selectedRows))
  }, [selectedAllRows])

  return <AllocatedUsersCard aria-label='allocate-users'>
    <AllocatedUserHeader>
      <AllocatedUserTitle>
        <div>
          Allocate users
        </div>
        <div>
          {numberOfRows} {recordType} selected
        </div>
      </AllocatedUserTitle>
      <AllocatedUsersCloseButtonContainer>
        <AllocatedUsersCloseButton
          title='Close popup'
          onClick={() => dismissPopup(false)}
        >
          <FontAwesomeIcon icon={faTimes as IconProp}/>
        </AllocatedUsersCloseButton>
      </AllocatedUsersCloseButtonContainer>
    </AllocatedUserHeader>
    <AllocatedUsersContentContainer>
      <AllocatedUsersContent>
        <AllocateToAllContainer>
          <Row align='center'>
            <Col>
              <Title level={4} marginTop='0'>Allocate to all</Title>
            </Col>
            <Col>
              <SPUDUserAutocomplete
                selectedOption={allocateToAllUser}
                selectOptionCallback={(user) => {
                  setAllocateToAllUser(user)
                  if (user && 'id' in user) {
                    const recordsToAllocate: Array<AllocateRecordType> = []
                    selectedRecords.forEach((record) => {
                      recordsToAllocate.push({
                        record_id: record.record_id,
                        allocated_user_id: user.id,
                      })
                    })
                    setRecordAllocationArr(recordsToAllocate)
                  } else {
                    setRecordAllocationArr([])
                  }
                }}
                users={users}
                // setting the unique id to 0 as recordIds
                // will be used for the records,
                // and no record will have the id of 0
                uniqueId={0}
              />
            </Col>
          </Row>
        </AllocateToAllContainer>
        <Row>
          <AllocateRecordsTable
            selectedRecords={selectedRecords}
            allocateToAllUser={allocateToAllUser}
            users={users}
            callback={(selectedUsers) => {
              let allocatedToAllRecords: AllocateRecordType[]
              let notAllocatedToAllRecords: AllocateRecordType[]
              if (allocateToAllUser) {
                // if the allocated to all record is selected,
                // we need to separate that out from the individual row changes
                // then find the difference and set the new list.
                // the reason for this is to ensure that any rows
                // that were set with the allocated to all user doesn't get lost
                // as selectedUsers doesn't include information
                allocatedToAllRecords = recordAllocationArr.filter(
                  record => record.allocated_user_id === allocateToAllUser.id,
                )
                notAllocatedToAllRecords = selectedUsers.filter(
                  record => record.allocated_user_id !== allocateToAllUser.id,
                )
                const diff = allocatedToAllRecords.filter(allocatedToAllRecord => {
                  return !notAllocatedToAllRecords.some(notAllocatedToAllRecord => {
                    return allocatedToAllRecord.record_id === notAllocatedToAllRecord.record_id
                  })
                })
                setRecordAllocationArr([...notAllocatedToAllRecords, ...diff])
              } else {
                setRecordAllocationArr(selectedUsers)
              }
            }}
          />
        </Row>
      </AllocatedUsersContent>
    </AllocatedUsersContentContainer>
    <AllocatedUsersControlBar>
      <AllocatedUsersConfirmButtonGroup>
        <AllocatedUsersControlButtons
          confirm={true}
          onClick={async () => {
            const response = await allocateUsers(recordAllocationArr)
            if (response) {
              dismissPopup(true)
            }
          }}
        >
          Allocate
        </AllocatedUsersControlButtons>
      </AllocatedUsersConfirmButtonGroup>
    </AllocatedUsersControlBar>
  </AllocatedUsersCard>
}

export default AllocateUsers
