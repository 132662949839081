import React, { useContext, useEffect, useState } from 'react'
import {
  Content,
  Row,
  Col, Dialog,
} from '@ix/ix-ui'
import BulkUpdateWelcomeComponent from './BulkUpdateWelcomeComponent'
import { BulkUpdateFlowStateType, flattenOpeningHoursObj } from './BulkAutoUpdateService'
import BulkAutoUpdateBreadcrumb from './BulkAutoUpdateBreadcrumb'
import SiteForm from './SiteForm'
import ServiceForm from './ServiceForm'
import ReviewForm from './ReviewForm'
import { transactionService } from '../../services/transactions.service'
import { SPUDOrganisationRecordDetails } from '../../../@types/Organisation.type'
import { SPUDSiteRecordDetails } from '../../../@types/Site.type'
import { BulkUpdateContext } from './BulkUpdateContext'
import { SPUDServiceRecordDetails } from '../../../@types/Service.type'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ServiceSeekerLogo from '../assets/serviceseeker-logo.e8faf7430aaf.png'
import { BulkUpdateSPUDRecordType } from './BulkUpdateContext.type'
import BulkUpdateFinalised from './BulkUpdateFinalised'
import { spudAPI } from '../../services/spud.service'
import { fixAgeGroups } from '../../helpers/record'

type Props = {
  accessCode?: string | undefined
}

function BulkAuoUpdateApp ({ accessCode }: Props) {
  const bulkUpdateContext = useContext(BulkUpdateContext)
  const [bulkAutoUpdateFlowState, setBulkAutoUpdateFlowState] = useState<BulkUpdateFlowStateType>('welcome')
  const [siteId, setSiteId] = useState<number>(0)
  const [expiredLink, setExpiredLink] = useState<boolean>(false)
  const showBreadCrumb = bulkAutoUpdateFlowState !== 'welcome'
  const fetchLinkedRecords = async (recordId: number) => {
    const response = await transactionService.linkedRecords({
      recordId: recordId,
      linkedRecordType: 'service',
      isBulkUpdate: true,
    })
    const ageGroupList = await spudAPI.fetchAgeGroups(
      {
        recordType: 'service',
        limit: 15,
        offset: 0,
        accessId: accessCode,
      })
    if (response.data.results.length && !bulkUpdateContext?.serviceInStorage(accessCode)) {
      bulkUpdateContext?.saveServiceData(response.data.results.map(service => {
        const serviceData = service.update.data as SPUDServiceRecordDetails
        const originalServiceData = {
          ...service,
          update: {
            ...service.update,
            data: {
              ...service.update.data,
              opening_hours_simple: flattenOpeningHoursObj(serviceData.opening_hours),
              age_groups: fixAgeGroups(serviceData.age_groups, ageGroupList),
            } as SPUDServiceRecordDetails,
          },
        }
        return {
          recordType: 'service',
          currentData: originalServiceData as BulkUpdateSPUDRecordType<SPUDServiceRecordDetails>,
          originalData: originalServiceData as BulkUpdateSPUDRecordType<SPUDServiceRecordDetails>,
          comment: '',
          confirmed: false,
          deleted: false,
          noChanges: false,
        }
      }))
    }
  }

  const fetchSite = async (recordId: string) => {
    try {
      const response = await transactionService.recordDetail({
        id: recordId,
        recordType: 'site',
        isBulkUpdate: true,
      })
      if (response.data.organisation && bulkUpdateContext) {
        !bulkUpdateContext.orgInStorage(accessCode) && bulkUpdateContext.saveOrganisationData({
          recordType: 'organisation',
          currentData: {
            id: response.data.organisation.id,
            update: {
              data: response.data.organisation,
            },
          } as BulkUpdateSPUDRecordType<SPUDOrganisationRecordDetails>,
          originalData: {
            id: response.data.organisation.id,
            update: {
              data: response.data.organisation,
            },
          } as BulkUpdateSPUDRecordType<SPUDOrganisationRecordDetails>,
          comment: '',
          confirmed: true,
          deleted: false,
          noChanges: false,
        })
        if (response.data.update && response.data.id) {
          setSiteId(response.data.id)
          if (!bulkUpdateContext?.site.currentData?.id && !bulkUpdateContext.siteInStorage(accessCode)) {
            let originalData = response.data
            const siteData = response.data.update.data as SPUDSiteRecordDetails
            if (!siteData.opening_hours_simple) {
              originalData = {
                ...response.data,
                update: {
                  ...response.data.update,
                  data: {
                    ...response.data.update.data,
                    opening_hours_simple: flattenOpeningHoursObj(siteData.opening_hours),
                  } as SPUDSiteRecordDetails,
                },
              }
            }

            bulkUpdateContext?.saveSiteData({
              recordType: 'site',
              currentData: originalData as BulkUpdateSPUDRecordType<SPUDSiteRecordDetails>,
              originalData: originalData as BulkUpdateSPUDRecordType<SPUDSiteRecordDetails>,
              comment: '',
              confirmed: false,
              deleted: false,
              noChanges: false,
            })
          }
          fetchLinkedRecords(response.data.id)
        }
      }
    // disabled because the type of error is unknown at run time
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response && error.response.data === 'link-expired') {
        setExpiredLink(true)
      }
    }
  }

  useEffect(() => {
    if (accessCode) {
      sessionStorage.setItem('bulk_update_code', accessCode)
      bulkAutoUpdateFlowState && setBulkAutoUpdateFlowState(
        bulkUpdateContext?.loadFlowState() || 'welcome',
      )
      if (!bulkUpdateContext?.loadData(accessCode)) {
        fetchSite(accessCode)
      }
    }
  }, [])

  if (!bulkUpdateContext) {
    return null
  }

  return (
    <Content style={{ backgroundColor: '#fff' }}>
      <Row>
        <Col align='center'>
          <img style={{ width: '20%', margin: '2em 0' }} src={ServiceSeekerLogo} alt="Service seeker"/>
        </Col>
      </Row>
      {showBreadCrumb && bulkAutoUpdateFlowState !== 'final' && <Row>
        <Col align='center'>
          <BulkAutoUpdateBreadcrumb
            bulkAutoUpdateFlowState={bulkAutoUpdateFlowState}
            setBulkAutoUpdateFlowState={(flowState: BulkUpdateFlowStateType) => {
              setBulkAutoUpdateFlowState(flowState)
              bulkUpdateContext?.setFlowState(flowState as BulkUpdateFlowStateType)
            }}
            {...bulkUpdateContext}
          />
        </Col>
      </Row>}
      <Row>
        {bulkAutoUpdateFlowState === 'welcome' && <Col align='center'>
          <BulkUpdateWelcomeComponent
            setBulkAutoUpdateFlowState={(flowState: BulkUpdateFlowStateType) => {
              setBulkAutoUpdateFlowState(flowState)
              bulkUpdateContext?.setFlowState(flowState as BulkUpdateFlowStateType)
            }}
            disableStart={!!(accessCode)}
            expiredLink={expiredLink}
          />
        </Col>}
        {bulkAutoUpdateFlowState === 'site' && accessCode && <Col align='center'>
          <SiteForm
            recordId={siteId}
            setBulkAutoUpdateFlowState={(flowState: BulkUpdateFlowStateType) => {
              setBulkAutoUpdateFlowState(flowState)
              window.scrollTo(0, 0)
              bulkUpdateContext?.setFlowState(flowState as BulkUpdateFlowStateType)
            }}
            {...bulkUpdateContext}
          />
        </Col>}
        {bulkAutoUpdateFlowState === 'service' && <Col align='center'>
          <ServiceForm
            recordId={siteId}
            setBulkAutoUpdateFlowState={(flowState: BulkUpdateFlowStateType) => {
              setBulkAutoUpdateFlowState(flowState)
              bulkUpdateContext?.setFlowState(flowState as BulkUpdateFlowStateType)
            }}
            {...bulkUpdateContext}
          />
        </Col>}
        {bulkAutoUpdateFlowState === 'review' && <Col align='center'>
          <ReviewForm
            setBulkAutoUpdateFlowState={(flowState: BulkUpdateFlowStateType) => {
              setBulkAutoUpdateFlowState(flowState)
            }}
            {...bulkUpdateContext}
          />
        </Col>}
        {bulkAutoUpdateFlowState === 'final' && <Col align='center'>
          <BulkUpdateFinalised />
        </Col>}
      </Row>
      {bulkUpdateContext?.dialogOptions?.show && (
        <Dialog
          onDismiss={() => {
            bulkUpdateContext.setDialogOptions({ ...bulkUpdateContext.dialogOptions, show: false })
            bulkUpdateContext.dialogOptions.onDismiss && bulkUpdateContext.dialogOptions.onDismiss()
          }}
          title={bulkUpdateContext.dialogOptions.title}
          message={bulkUpdateContext.dialogOptions.description}
          type={bulkUpdateContext.dialogOptions.type || 'acknowledge'}
          onConfirm={(value: string | undefined) => {
            bulkUpdateContext.setDialogOptions({ ...bulkUpdateContext.dialogOptions, show: false })
            bulkUpdateContext.dialogOptions.onConfirm && bulkUpdateContext.dialogOptions.onConfirm(value)
          }}
        />
      )}
    </Content>
  )
}

export default BulkAuoUpdateApp
